export const parseSearch = (q = '') => {
    if (!q) {
        return;
    }

    let query = q;

    if (q[0] == '?' || q[0] == '#') {
        query = query.substring(1);
    }

    const splitted = query.split('&');
    const obj = {};

    splitted.forEach((s) => {
        const arr = s.split('=');
        obj[arr[0]] = arr[1];
    });

    return obj;
};
