import React from 'react';
import bemCn from 'bem-cn';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import PictureSet from '../picture-set';
import SvgKeyboardRight from 'src/assets/svg/SvgKeyboardRight.svg';
import './style.scss';

const b = bemCn('blog-article-desktop-high');

const BlogArticleDesktopHigh = ({ data, useStateSSR, method = 'lazy' }) => {
    const thumbnail = data.thumbnail ? ImageHelper.getUrlFromOptions(data.thumbnail, `fit-in/${IMG_RESIZES.x0256}`) : defSpotImg;
    const desc = data.descriptionShort || data.description;

    return (
        <a href={data.slug || data.url} className={b()}>
            <PictureSet
                imgs={thumbnail}
                className={b('thumbnail')}
                width={382}
                height={264}
                useState={useStateSSR}
                SSR={!!useStateSSR}
                alt={data.title + ' thumbnail'}
                method={method}
            />
            <div className="pt-3 px-3 py-4">
                <h4 className={b('title')}>{data.title}</h4>
                <div className={b('desc')} dangerouslySetInnerHTML={{ __html: desc }} />
                <p className={b('read-more')}>
                    Read more
                    <SvgKeyboardRight />
                </p>
            </div>
        </a>
    );
};

export default BlogArticleDesktopHigh;
